import gql from "graphql-tag";

const SECTIONS_QUERY = gql`
  query {
    sections {
        id
        title
        sectionBody {
            html
        }
        image {
            id
            url
        }
    }   
  }
`;

const DISTRIBUTORS_QUERY = gql`
  query {
    distribution_centers {
        name
        address
        emailAddress
        city
        phoneNumber
        coordinates {
            latitude
            longitude
        }
    }   
  }
`;

export {
    SECTIONS_QUERY, 
    DISTRIBUTORS_QUERY,
}