import React from 'react';
import { Div, Container, Col} from "atomize";
import ReactPlayer from 'react-player'
import video from "../videos/video.mp4"
import thumb from "../images/player-thumb.png"


const VideoSection = () => {
    return (
        <>
            <Div 
                shadow="3" 
                bg="dark" 
                textAlign="center"
                
            >
                
                <Container  
                    align="center"                           
                    d="flex"
                >

                    <Col 
                        size="9" 
                        p={{ y : "1rem"}} 
                        textAlign="center" 
                        d="flex" 
                        justify="center" 
                    >
                        
                        <ReactPlayer 
                            height="30rem"
                            width="90vw"
                            url={video}
                            light={thumb}
                            playing={true}
                            controls={true}
                            /> 
                    
                    </Col>

                </Container>
            </Div>
        </>
    );
};

export default VideoSection;