import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { DISTRIBUTORS_QUERY } from '../components/api-call/index';
// Navbar
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import MapSection from "../components/MapSection"

class Distributors extends Component {
  
  render() {
    return (  
      <Query query={DISTRIBUTORS_QUERY}>
        
        {({ loading, error, data }) => {
              if (loading) return <div></div>
              if (error)   return <div></div>
              const distributors = data.distribution_centers              
              
               return (
                <>
                  <Navbar/>
                  <MapSection data={distributors} />
                  <Footer/>
                </>
              )
            }}
      </Query>
    );
  }
};


export default Distributors;
 