import React from 'react';
import { Container, Div, Col, Row, Text, Icon, Image, Anchor } from "atomize";
import logo from "../images/IGLA_logo.png"

const Footer = () => {
  return (
      <Div  bg="dark" textColor="white" p={{t:{xs:"2rem", md:"0rem"}, b: {xs: '4rem', lg:"1rem"}}}>

        <Container>
        <Row>
                <Col    size={{ xs: 9, md:3}} p="2rem" textAlign="center" align="center">
                    <Text tag="h1" textSize="title" m={{ b: "2rem" }}>
                        Social media
                    </Text>
                    <Anchor href="https://www.instagram.com/iglasrbija" textDecor="none" textColor="white" hoverTextColor="gray800">
                        <Row  align="center" p={{x:{xs:"2rem", md:"1rem", lg:"5rem"}}}>
                            <Col size={{ xs: 3, md:2}} textAlign="right">
                                <Icon name="Instagram" size="45px" color="white"/>
                            </Col>
                            <Col size={{ xs: 3, md:1}} p={{x:"1rem"}}>
                                <Text tag="h1" textSize="paragraph" textWeight="400" >
                                    Instagram
                                </Text>
                            </Col>
                        </Row>
                    </Anchor>
                    <Anchor href="https://www.facebook.com/IglaSrbija" textDecor="none" textColor="white" hoverTextColor="gray800">
                        <Row  align="center" p={{x:{xs:"2rem", md:"1rem", lg:"5rem"}}}>
                            <Col size={{ xs: 3, md:2}} textAlign="right">
                                <Icon name="Facebook" size="45px" color="white"/>
                            </Col>
                            <Col size={{ xs: 3, md:1}} p={{x:"1rem"}}>
                                <Text tag="h1" textSize="paragraph" textWeight="400" >
                                    Facebook
                                </Text>
                            </Col>
                        </Row>
                    </Anchor>    
                    </Col>
                <Col size={{ xs: 9, md:3}} p="2rem"  textAlign="center" >                    
                    <Text tag="h1" textSize="title" m={{ b: "2rem" }}>
                        Contacts
                    </Text>
                    <Anchor href="tel:+381668477433" textDecor="none" textColor="white" hoverTextColor="gray800">
                        <Text tag="h1" textSize="paragraph" textWeight="400" 
                            m={{t:"2.7rem"}}>
                            Telefon: +381 66 477 433
                        </Text>
                    </Anchor>
                    <Anchor href="mailto:igla@iglasrbija.rs" textDecor="none" textColor="white" hoverTextColor="gray800">
                        <Text tag="h1" textSize="paragraph" textWeight="400" 
                            m={{t:"1.4rem"}}>
                            Email: igla@iglasrbija.rs
                        </Text>
                    </Anchor>
                    </Col>
                <Col size={{ xs: 9, md:3}} p="2rem"  textAlign="center">                    
                    <Text tag="h1" textSize="title" m={{ b: "2rem" }}>
                        Credits
                    </Text>
                    <Anchor href="https://www.linkedin.com/in/aleksandar-kostic-509118189/" textDecor="none" textColor="white" hoverTextColor="gray800">
                        <Text tag="h1" textSize="paragraph" textWeight="400" 
                            m={{t:"2.7rem"}}>
                            Aleksandar Kostić
                        </Text>
                    </Anchor>
                    <Anchor href="https://www.linkedin.com/in/francescotomaselli-/" textDecor="none" textColor="white" hoverTextColor="gray800">
                        <Text tag="h1" textSize="paragraph" textWeight="400" 
                            m={{t:"1.4rem"}}>
                            Francesco Tomaselli
                        </Text>
                    </Anchor>
                </Col>
            </Row>
            <Row align="center" p={{x:{xs:"2rem", md:"5rem"}}}>
                    <Col size={{ xs: 9, md:4}} textAlign={{xs: "center", md: "right"}}>
                        <Image src={logo} w="9rem"/>
                    </Col>
                    <Col size={{ xs: 9, md:5}} textAlign={{xs: "center", md: "left"}}>
                        <Text tag="h1" textSize="paragraph" textWeight="400" >
                            © 2021 AE KOSTIĆ
                        </Text>
                    </Col>
            </Row>
            <Row >
                <Col textAlign="center">
                    <Text tag="h1" textSize="paragraph" textWeight="400" >
                    The main activity is the development and manufacture of CAN-devices to protect vehicles from theft.
                    </Text>
                </Col>
            </Row>
        </Container>
      </Div>
  );
};

export default Footer;