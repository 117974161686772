import React from 'react';
import { Div, Text, Row, Col, Image, Container, Anchor, SideDrawer, Icon } from "atomize";
import navlogo from "../images/logo_nav.png"

// navbar items as array, there will be two maps later
const navbarItems = [
        {
            "display" : "IGLA",
            "link": "/"
        },  
        {
            "display" : "IGLA ALARM",
            "link": "/igla-alarm"
        },  
        // {
        //     "display" : "KEYLESS BLOCK",
        //     "link": "/coming-soon"
        // },  
        {
            "display" : "TS100",
            "link": "/ts100"
        },  
        {
            "display" : "INSTALACIONI CENTRI",
            "link": "/distributors"
        } 
    ]

const SizeSideDrawer = ({ isOpen, onClose }) => {
    return (
      <SideDrawer isOpen={isOpen} bg="dark" onClose={onClose} w={{ xs: "100vw", md: "50vw" }}>

        {/* close icon */}
        <Div d="flex" justify="flex-end">
            <Icon 
                name="Cross" size="2.5rem" m={{t: {xs: "-0.5rem", md:""}}} color="white"
                onClick={onClose}
                >
            </Icon>
        </Div>

        {/* Drawer links */}
        {navbarItems.map((item) => (
            <Div m="2rem" d="flex" align="center">

                <Anchor href={item.link} d="block" textDecor="none" 
                        textColor="white" hoverTextColor="gray800" >
                    <Text tag="h1" textSize="subheader" textAlign="center" textWeight="500">
                        {item.display}
                    </Text>
                </Anchor>
            </Div>
        ))}
      
      </SideDrawer>
    );
  };


  class Navbar extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        showSideDrawer: false
      };
    }
    render() {
      const { showSideDrawer } = this.state;
      return (
        <>
            <Div bg="dark" d="flex">
                <Container d="flex" align="center">
                    <Row d="flex" p={{y : {xs : "1rem", md: "0.5rem", lg:"1rem"}}}>
                        
                        {/* logo */}
                        <Col size={{ xs : 4, md : 3, lg: 2}} >
                                <Anchor href="/" d="flex" align="center" textDecor="none">
                                    <Div d="flex" align="center">
                                        <Image src={navlogo}/>
                                    </Div>
                                </Anchor>
                        </Col>
                        {/* navbar links, only on large and xlarge devices */}
                        <Col d={{ xs: "none", md:"none", lg: "flex"}}  h="100%" justify="flex-end" size={{ lg: 7}} >
                                {navbarItems.map((item) => (
                                    <Div p={{t : "0.5rem"}}m={{l:"2.5rem"}} h="100%" d="flex">
                                        <Anchor href={item.link} textDecor="none" >
                                            <Text tag="h1" textSize="subheader" textAlign="center" 
                                                  textColor={(window.location.pathname === item.link)? "white" : "disabled"} 
                                                  hoverTextColor="white" textWeight="500">
                                                {item.display}
                                            </Text>
                                        <Div h="6%" w="100%" bg={(window.location.pathname === item.link)? "white" : "dark"}></Div>
                                        </Anchor>
                                    </Div>
                                
                                ))}
                        </Col>
                        {/* Menu icon only display on medium and small */}
                        <Col d={{ xs: "flex", md:"flex", lg:"none"}} 
                             justify="flex-end" align="center" 
                             size={{xs : 5, md: 6}}>
                            
                            <Icon 
                                name="Menu" size="2rem" color="white"
                                onClick={() =>
                                    this.setState({
                                        showSideDrawer: true,
                                    })
                                }
                                >
                                </Icon>
                                <SizeSideDrawer
                                    isOpen={showSideDrawer}
                                    onClose={() => this.setState({ showSideDrawer: false })}
                                />

                        </Col>
                    </Row>
                </Container>
            </Div>
        </>
      );
    }
}

export default Navbar;