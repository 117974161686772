import React, { Component } from 'react';
// Navbar
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"

import { Div, Text } from "atomize";


class IglaAlarm extends Component {
  
  render() {
    return (  
        <>
            <Navbar/>
                <Div  h="40rem" d="flex" align="center" justify="center">
                    <Text  textSize="display1" > U IZRADI </Text>              
                </Div>
            <Footer/>
        </>
    );
  }
};


export default IglaAlarm;
 