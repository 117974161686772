import { React, Component } from "react"
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import { Container, Div, Anchor, Text, Dropdown } from "atomize";

const mapStyles = {
  width: '100%',
  height: '100%'
};

const containerStyle = {
  position: 'relative',  
  width: '100%',
  height: '45rem',
}

export class MapSection extends Component {
  
  // constructor(props) {
  //   super(props);
  // }
  state = {
    showingInfoWindow: false,  // Hides or shows the InfoWindow
    activeMarker: {},          // Shows the active marker upon click
    selectedPlace: {},          // Shows the InfoWindow to the selected place upon a marker
    showDropdown: false,
    center: {
      lat : 44.43066260208989, 
      lng : 19.40114033628608
    },
    zoom : 8, 
    dropdownPlaces : "Izaberi lokaciju" 
  };

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
  });

  onClose = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  removeDuplicates(l) {
    const s = new Set();
    const r = [];
    for (let i = 0; i < l.length; i++) {
      const element = l[i];
      if (!s.has(element.display)) {
        r.push(element);
        s.add(element.display);
      }
    }
    return r.sort((a,b) => a.display > b.display);
  }

  dropdownPlaces = this.removeDuplicates(this.props.data.map((item) => (
    {
      "display" : item.city,
      "center" : {
        lat: item["coordinates"]["latitude"],
        lng: item["coordinates"]["longitude"]
      }
    } 
  )))
  

  menuList = (
    <Div p="1rem">
      {this.dropdownPlaces.map((item) => (
        <Anchor 
          textDecor="none"
          textColor="black700"
          d="block" 
          p={{ y: "0.25rem" }}
          onClick={() => {
              this.setState({ center : item.center });
              this.setState({ zoom : 12 });
              this.setState({ showDropdown : !this.state.showDropdown });
              this.setState({ dropdownPlaces  : item.display})
          }}>
          {item.display}
        </Anchor>
      ))}
    </Div>
  );

  render() {
    return (
        <>
        <Container p={{y : "1rem", t : "2rem", x : "1rem"}} d="flex" align="center" justify="center">
            <Text tag="p" textSize="subheader" textWeight="400" textAlign="center"
                textColor="black700">
                Izabrati jednu od ponuđenih lokacija za ugradnju IGLE na vašem automobilu
            </Text>
        </Container>
        <Div d="flex" m={{b : {xs: "1rem", md: "0rem", lg: "0rem"}}}justify="center" align="center">
            <Dropdown
              w="20rem"
              isOpen={this.state.showDropdown}
              onClick={() => {
                  this.setState({ showDropdown: !this.state.showDropdown });
              }}
              menu={this.menuList}
            >
              {this.state.dropdownPlaces}
            </Dropdown>
        </Div>
        <Div p={{ xs: "0rem", md: "2rem", lg : {x : "3rem", b:"3rem", t : "1rem"}}}>
        <Map 
            google={this.props.google} 
            initialCenter={this.state.center}
            style={mapStyles}
            containerStyle={containerStyle}
            center={this.state.center}
            zoom={this.state.zoom}
            >

            {this.props.data.map((item) => (
              <Marker
                onClick={this.onMarkerClick}
                name={item["name"]}
                address={item["address"]}
                emailAddress={item["emailAddress"]}
                phoneNumber={item["phoneNumber"]}
                position={{lat: item["coordinates"]["latitude"], lng: item["coordinates"]["longitude"]}}
              />
              ))}

            <InfoWindow
              marker={this.state.activeMarker}
              visible={this.state.showingInfoWindow}
              onClose={this.onClose}
              >
              <div style={{"text-align" : "center"}}>
                <h1>{this.state.selectedPlace.name}</h1>
                <p>{this.state.selectedPlace.address}</p>
                <p>{this.state.selectedPlace.emailAddress}</p>
                <p>{this.state.selectedPlace.phoneNumber}</p>
              </div>
            </InfoWindow>
          </Map>
        </Div>

        </>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCmvq-7kY9oqiCpvuN1hePRh6j-p3h5Jcs"
})(MapSection) 