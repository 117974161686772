import React from 'react';
import { Div, Text, Row, Col, Container} from "atomize";
const Section = ({title, paragraph, image, shadow}) => {
    return (
        <>
            <Div shadow={shadow} p={{t: "1rem"}}>

            <Container textAlign="center"
                       textColor="dark" >
                <Row align="center" p={{ x : {xs:"0rem", md:"2rem"},
                                         y : {xs:"1rem", md:"2rem"}}}>
                    <Col size={{ xs: 9, md: 4}}>
                        <Div bgImg= {image}
                             rounded="xl"
                             shadow="3"
                             h={{xs : "15rem", md : "20rem"}}
                             bgSize="cover"
                             bgPos="center">
                        </Div>
                    </Col>
                    <Col size={{ xs: 9, md: 5 }} p={{ l : {xs:"0rem", md:"3rem"}, 
                                                      y : {xs:"2rem", md:"0rem"}}}>
                        <Div textAlign="Left" >
                            
                            <Text tag="h1" textSize="display2" textAlign="center" textWeight="500" m={{b : "1rem"}}>
                                {title}
                            </Text>
                            <Text tag="p" textSize="subheader" textWeight="400" textAlign="justify"
                                textColor="black700">
                                {paragraph}
                            </Text>
                        </Div>
                    </Col>
                </Row>
                </Container>
            </Div>
        </>
    );
};

export default Section;