import './App.css';
import Homepage from './pages/homepage.js'
import Distributors from './pages/distributors.js'
import Ts100 from './pages/ts100.js'
import IglaAlarm from './pages/igla-alarm.js'

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

function App() {
  return (
    <>
      <Router>
        <Switch>
            <Route path="/igla-alarm">
              <IglaAlarm/>
            </Route>
            <Route path="/ts100">
              <Ts100/>
            </Route>
            <Route path="/distributors">
              <Distributors/>
            </Route>
            <Route path="/">
              <Homepage/>
            </Route>
          </Switch>
      </Router>
    </>
  );
}

export default App;
