import React from 'react';
import ReactDOM from 'react-dom'
import { ThemeProvider, StyleReset } from 'atomize';
import App from './App';
import { Provider as StyletronProvider, DebugEngine } from "styletron-react"
import { Client as Styletron } from "styletron-engine-atomic"
import { ApolloProvider } from "react-apollo";
import ApolloClient from "apollo-boost";

const client = new ApolloClient({
  uri: "https://api-eu-central-1.graphcms.com/v2/ckmi3gfvem3uj01xndyts0cic/master"
});

const debug =
process.env.NODE_ENV === "production" ? void 0 : new DebugEngine()

const engine = new Styletron()

const theme = {
  grid: {
    colCount: 9,
    gutterWidth: 0
  }
};

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <StyletronProvider value={engine} debug={debug} debugAfterHydration>
      <StyleReset />
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </StyletronProvider>
  </ThemeProvider>,
  document.getElementById('root')
)