import React from 'react';
import { Div } from "atomize";
import pay1 from "../images/payoff.jpg"

const Payoff = () => {
    return (
        <>
            <Div bgImg= {pay1} bgSize="cover" bgPos={{xs: "right bottom", md : "right bottom", lg: "left bottom"}}
                 h={{xs: "calc(70vh)", md: "35rem"}}
                 top="0" right="0"
                 textAlign={{md: "left", xs: "center"}}
                 textColor="white" shadow="4" d="flex" align={{md: "top", xs: "center", lg:"top"}}
                >
            </Div>
        </>
    );
};

export default Payoff;