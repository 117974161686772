import React from 'react';
import { Div, Text, Image} from "atomize"

const Card = ({title, paragraph, image}) => {
    return (
        <>
            <Div
                bg='transparent' shadow='3' rounded="xl"
                m={{
                    x : { xs: "0rem", md:"auto"}, 
                    y : { xs: "1.5rem" }
                }}
                border="1px solid" borderColor="gray300"
                w={{ xs: '', md: '22rem' }}
                h={{ xs: '', md: '36rem', lg: "30rem" }}
                p={{
                    x: { xs: '2rem', md: '2rem' },
                    b: { xs: '2rem', md: '2rem' }
                }}
                >
                    <Div p={{ x: '2rem', t: '2rem' }}>
                        <Image src={image} w={{ md: '', lg:"8rem" }}/>
                    </Div>
                    
                    <Text tag="h1" textSize="display1" textAlign="center" 
                          textWeight="500">
                            {title}
                    </Text>

                    <Text tag="p" textSize="paragraph" textWeight="400" 
                          textAlign="justify" m={{t:"1rem"}}
                          textColor="black700">
                        {paragraph}
                    </Text>
                </Div>
        </>
    );
};

export default Card;