import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { SECTIONS_QUERY } from '../components/api-call/index';
// Navbar
import Navbar from "../components/Navbar"
// Hero section with image
import Hero from "../components/Hero"
import Heroimg from "../images/hero.png"

// Sections and text sections
import Section from "../components/Section"
import TextSection from "../components/TextSection"

// Cards and payoff
import CardSection from "../components/CardSection"
import Payoff from "../components/Payoff"

import VideoSection from '../components/VideoSection';

import Footer from "../components/Footer"

import ReactHtmlParser from 'react-html-parser';

class Homepage extends Component {
  // constructor(props) {
  //   super(props);
  // }
  
  render() {
    return (  
      <Query query={SECTIONS_QUERY}>
        
        {({ loading, error, data }) => {
              if (loading) return <div></div>
              if (error)   return <div></div>
              const sections = data.sections              
              
               return (
                <>
                  <Navbar/>
                  <Hero 
                        // text={sections[0]['sectionBody']['html'].replace(/<[^>]+>/g, '')}
                        image={Heroimg}/>

                  <TextSection id="introSection"
                            paragraph={ReactHtmlParser(sections[0]['sectionBody']['html'])}
                            image={ReactHtmlParser(sections[0]['image'][0]['url'])}
                            />

                  <Section  title={ReactHtmlParser(sections[1]['title'])} 
                            paragraph={ReactHtmlParser(sections[1]['sectionBody']['html'])}
                            image={ReactHtmlParser(sections[1]['image'][0]['url'])}/>
                  
                  <TextSection  title={ReactHtmlParser(sections[4]['title'])} 
                            paragraph={ReactHtmlParser(sections[4]['sectionBody']['html'])}
                            image={ReactHtmlParser(sections[4]['image'][0]['url'])}
                            shadow="4"/>

                  <Payoff/>  
                  
                  <CardSection/>  

                  <VideoSection/>

                  
                  <Section  title={ReactHtmlParser(sections[2]['title'])} 
                            paragraph={ReactHtmlParser(sections[2]['sectionBody']['html'])}
                            image={ReactHtmlParser(sections[2]['image'][0]['url'])}
                            />
                  
                  <TextSection  
                            paragraph={ReactHtmlParser(sections[3]['sectionBody']['html'])}
                            image={ReactHtmlParser(sections[3]['image'][0]['url'])}
                            shadow="4"/>
                  
                  <Footer/>
                </>
              )
            }}
      </Query>
    );
  }
};

// {items.map(item => <Product key={item.id} product={item} addItem={this.addItem} />)}


        

export default Homepage;
 