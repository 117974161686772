import React from 'react';
import { Text, Container, Div} from "atomize";
import Card from "./Card"
import Relay from "../images/relay.svg"
import Pcb from "../images/pcb.svg"
import Cable from "../images/cable.svg"
import Dup from "../images/dup.svg"
import Stole from "../images/stole.svg"
import Thief from "../images/thief.svg"

const CardSection = () => {

    // cards as variables, so responsiveness in easier
    
    const a = (<Card title="Relejni napad"
                     paragraph="Takozvani relejni napad obično izvode dvoje ljudi koji rade zajedno. Jedan prati vozača ciljanog vozila kako bi kopirao signal iz originalne priveske za ključeve i poslao ga svom partneru koji je u blizini automobila. Jednom kada je signal primljen, samo je nekoliko sekundi potrebno „zavarati“ sistem ulaska bez ključa i odvesti automobil."
                     image={Relay}/>)

    const b = (<Card title="Hakovanje OBD porta"
                     paragraph="Hakovanje OBD-II porta može se dogoditi bukvalno bilo kome, bilo da je automobil na parkingu ili čak u autoservisnom centru. Jednostavnom dijagnostičkom opremom postoji način da se klonira ključ i posle ukrade automobil."
                     image={Cable}/>)

    const c = (<Card title="Kloniranje OE ključa"
                     paragraph="Uređaji koje kriminalci koriste za kloniranje ključa u potpunosti su dostupni na Internetu. Kod OE ključa se lako može zgrabiti i koristiti za novi ključ koji će lopovi koristiti za pokretanje automobila."
                     image={Dup}/>)

    const d = (<Card title="Zamena ECU bloka"
                     paragraph="Jednom kada 'mozak' automobila zamene drugi koji su prethodno programirali lopovi, kriminalcima više nije problem pokretanje automobila."
                     image={Pcb}/>)

    const e = (<Card title="Otmica automobila"
                     paragraph="U nekim zemljama otmica automobila ostaje veliki problem: ljudi su prisiljeni da ostavljaju automobile sa svim stvarima unutra."
                     image={Thief}/>)

    const f = (<Card title="Ukradeni fabrički ključ"
                     paragraph="Krađa originalnih ključeva automobila i dalje je popularna među lopovima, što se može dogoditi bilo gde."
                     image={Stole}/>)

    return (
        <>
        <Div shadow="4">
            <Container  textAlign="center"
                        textColor="Dark"
                        p="1rem">

                <Text tag="h1" 
                    textSize="display2" 
                    textWeight="500" 
                    m={{
                        y: '2rem',
                        x: '1rem'
                    }}>
                    Koji su načini krađe automobila?
                
                </Text>

                {/* Divs for large and xlarge */}
                <Div  d={{ xs: "none", md:"none", lg: "flex"}}>
                    {a} {b} {c}                        
                </Div>

                <Div  d={{ xs: "none", md:"none", lg: "flex"}}>
                    {d} {e} {f}                        
                </Div>

                {/* Divs for mobile and medium */}

                <Div  d={{ xs: "", md:"flex", lg: "none"}}>
                    {a} {b}
                </Div>
                
                <Div  d={{ xs: "", md:"flex", lg: "none"}}>
                    {c} {d}
                </Div>
                
                <Div  d={{ xs: "", md:"flex", lg: "none"}}>
                    {e} {f}                        
                </Div>

                </Container>
            </Div>
        </>
    );
};

export default CardSection;