import React from 'react';
import { Container, Div, Row, scrollTo, Icon, Anchor } from "atomize";

const Hero = ({ text, image }) => {
    return (
        <>
            <Div bgImg= {image}
                bgSize="cover"
                 bgPos="right bottom">
                <Div
                    h={{ xs : "80vh", md : "40rem", lg : "40rem"}}
                    top="0"
                    right="0"
                    textAlign={{xs: "center", md: "right"}}
                    textColor="white"
                    shadow="4"
                    >
                    <Container p={{l:{xs: "0rem", lg: "36rem"}}} d="flex" align="right" justify="flex-end">
                            <Row d={{ xs: "flex", md: "flex", lg: "none" }}  align="center">
                                <Anchor textColor="white" hoverTextColor="gray800" textDecor="none" textSize="subheader" textAlign="right" textWeight="400" onClick={() => scrollTo("#introSection")}>
                                    Saznaj više
                                </Anchor>
                                <Icon 
                                name="DownArrowSolid" size="40px" color="white" hoverColor="gray800" m="1.2rem"
                                onClick={() => scrollTo("#introSection")}/>
                            </Row>
                        {/* <Text   tag="h1" 

                                textSize={{xs: "title", md: "display1"}} 
                                textWeight="300"
                                >
                            {text}
                        </Text>
                        {/* <Text   tag="h1" 
                                textSize="display3" 
                                textWeight="500"
                                textTransform="uppercase"
                                >
                            {title}
                        </Text> */}
                    </Container>
                </Div>
            </Div>
        </>
    );
};

export default Hero;